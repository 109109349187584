.payment {
    background-color: #ffffff;
    border-radius: 2rem;
    padding: 1rem;
    width: 60vw;
    align-self: center;
    align-items: center;
    justify-self: center;
}

form {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    align-items: center;
    justify-content: center;

}

p {
    text-align: center;
    font-weight: bolder;
    font-size: xx-large;
}

.left-f {
    padding: 1rem;
    margin: 2rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.right-f {
    padding: 1rem;
    margin: 2rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

input[type=text],
input[type=number],
select {
    width: 100%;
    padding: 2%;
    border-radius: 5px;
    border: 1px solid black;
}

input[type=text]:hover,
input[type=number]:hover {
    border: 2px solid blue;
}
select option{
    font-size: large;
}
label {
    display: flex;
    flex-direction: row;
}

.pay-btn{
    width: 100%;
    padding: 2%;
    border-radius: 5rem;
    color: white;
    background-color: black;
    cursor: pointer;
}
.pay-btn:hover{
   background-color:#393e46 ;
   font-weight: bolder;
}

.back{
    position: fixed;
    top:1rem;
    left: 75%;
}

.back:hover {
    color: green;
}

@media screen and (max-width: 768px){
 
    .payment{
        width: 100vw;
    }
 
    form{
      flex-direction: column;
      padding: 0;
    }
    .left-f{
        padding: 0;
    }
    .right-f{
        padding: 0;
    }
    .back{
        left: 85%;
    }
    
}