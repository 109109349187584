.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem; 
    justify-content: center;
}
.left-t{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-left: 3rem;
    gap: 2rem;
    text-transform: uppercase;
    color: white;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
    font-size: xx-large;
}

.left-t>:nth-child(2),
.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
    color: white;
    background-color: darkorange;
    border-radius: 2rem;
    border:  2px solid red;
    box-shadow: 5px 10px 10px gray;
    padding: 3%;
}
.right-t{
    flex:1;
    position: relative;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top:0.9rem;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background: var(--planCard);

}
.arrows{
  justify-content: space-between;
  align-self: center;
}
.arrow-r, .arrow-l{
  cursor: pointer;
  background-color: white;
  color: darkorange;

}
.arrow-l{
  position: absolute;
  left:0;

}
.arrow-r{
  position: absolute;
  right: 0;
}
@media screen and (max-width: 768px){
  .Testimonials{
    flex-direction: column;
  }
  .left-t>:nth-child(2),
  .left-t>:nth-child(2){
    font-size: xx-large;
  }
  .right-t{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
  .right-t>div{
    position: relative;
   display: none;
  }
  .right-t>img{
    top: 0rem;
    right: 0rem;
    position: relative;
    align-self: center;
  }
  .right-t>:last-child{
    display: block;
    bottom: 0;
    left: 0;
    
  }
  


}