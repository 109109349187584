.header{
    display: flex;
    justify-content: space-between;
    background-color: black;
    padding: 1rem;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.header-menu{
   list-style: none;
   display: flex;
   gap: 2rem;
   color: white;
}

.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}
.user-name{
    font-size: small;
    cursor: none;
}
.user-name span span{
    font-weight: bold;
}

@media screen and (max-width: 768px){
 .header>:nth-child(2){
    position: fixed;
    right: 2rem;
    z-index: 99;
 }
 .header-menu{
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
    
 }


}